var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('main', [_c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_c('b-card', [_c('b-row', {
    staticClass: "align-items-center"
  }, [_c('b-col', {
    staticClass: "my-1",
    attrs: {
      "sm": "12",
      "md": "6"
    }
  }), _c('b-col', {
    staticClass: "d-flex justify-content-end",
    attrs: {
      "sm": "12",
      "md": "6"
    }
  }, [_c('h2', {
    staticClass: "text-success text-right"
  }, [_vm._v(" Total: "), _c('strong', [_vm._v("Rp " + _vm._s(_vm.formatRupiah(this.masuk)))])])])], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "xl": "2",
      "lg": "2",
      "md": "4",
      "sm": "4"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": "Per Page",
      "label-for": "perPageSelect"
    }
  }, [_c('b-form-select', {
    attrs: {
      "id": "perPageSelect",
      "size": "sm",
      "options": _vm.pageOptions
    },
    model: {
      value: _vm.perPage,
      callback: function callback($$v) {
        _vm.perPage = $$v;
      },
      expression: "perPage"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "xl": "10",
      "lg": "10",
      "md": "8",
      "sm": "8"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": "Filter",
      "label-for": "filterInput"
    }
  }, [_c('b-input-group', [_c('b-form-input', {
    attrs: {
      "id": "filterInput",
      "size": "sm",
      "type": "search",
      "placeholder": "Tekan enter untuk cari"
    },
    on: {
      "keydown": function keydown($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        $event.preventDefault();
        return _vm.getTransaksi();
      }
    },
    model: {
      value: _vm.filter,
      callback: function callback($$v) {
        _vm.filter = $$v;
      },
      expression: "filter"
    }
  }), _c('b-input-group-append', [_c('b-button', {
    attrs: {
      "disabled": !_vm.filter,
      "size": "sm"
    },
    on: {
      "click": function click($event) {
        _vm.filter = '';
        _vm.getTransaksi();
      }
    }
  }, [_vm._v(" Clear ")])], 1)], 1)], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    staticClass: "mt-1",
    attrs: {
      "xl": "4",
      "lg": "4",
      "md": "4",
      "sm": "12"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": "Status Bayar",
      "label-for": "status_bayar"
    }
  }, [_c('b-input-group', [_c('b-form-select', {
    attrs: {
      "size": "sm",
      "id": "status_bayar",
      "options": _vm.optstatus_bayar
    },
    on: {
      "input": function input($event) {
        return _vm.getTransaksi();
      }
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function fn() {
        return [_c('b-form-select-option', {
          attrs: {
            "value": null
          }
        }, [_vm._v("-- Semua --")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.status_bayar,
      callback: function callback($$v) {
        _vm.status_bayar = $$v;
      },
      expression: "status_bayar"
    }
  })], 1)], 1)], 1), _c('b-col', {
    staticClass: "mt-1",
    attrs: {
      "xl": "4",
      "lg": "4",
      "md": "4",
      "sm": "12"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": "Status",
      "label-for": "statuskirim"
    }
  }, [_c('b-input-group', [_c('b-form-select', {
    attrs: {
      "size": "sm",
      "id": "statuskirim",
      "options": _vm.optstatuskirim
    },
    on: {
      "input": function input($event) {
        return _vm.getTransaksi();
      }
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function fn() {
        return [_c('b-form-select-option', {
          attrs: {
            "value": null
          }
        }, [_vm._v("-- Semua --")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.statuskirim,
      callback: function callback($$v) {
        _vm.statuskirim = $$v;
      },
      expression: "statuskirim"
    }
  })], 1)], 1)], 1), _vm.isFinance || _vm.isOwner ? _c('b-col', {
    staticClass: "mt-1",
    attrs: {
      "xl": "4",
      "lg": "4",
      "md": "4",
      "sm": "12"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": "Daftar yang sudah masuk ke akun dan kas",
      "label-for": "is_accounted"
    }
  }, [_c('b-input-group', [_c('b-form-select', {
    attrs: {
      "size": "sm",
      "id": "is_accounted",
      "options": _vm.optis_accounted
    },
    on: {
      "input": function input($event) {
        return _vm.getTransaksi();
      }
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function fn() {
        return [_c('b-form-select-option', {
          attrs: {
            "value": null
          }
        }, [_vm._v("-- Semua --")])];
      },
      proxy: true
    }], null, false, 437768337),
    model: {
      value: _vm.is_accounted,
      callback: function callback($$v) {
        _vm.is_accounted = $$v;
      },
      expression: "is_accounted"
    }
  })], 1)], 1)], 1) : _vm._e()], 1), _c('b-row', [_c('b-col', {
    staticClass: "mt-1",
    attrs: {
      "cols": "12"
    }
  }, [_c('b-table', {
    attrs: {
      "striped": "",
      "small": "",
      "hover": "",
      "responsive": "",
      "per-page": _vm.perPage,
      "items": _vm.items,
      "fields": _vm.fields,
      "sort-by": _vm.sortBy,
      "sort-desc": _vm.sortDesc,
      "sort-direction": _vm.sortDirection
    },
    on: {
      "update:sortBy": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sort-by": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sortDesc": function updateSortDesc($event) {
        _vm.sortDesc = $event;
      },
      "update:sort-desc": function updateSortDesc($event) {
        _vm.sortDesc = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "cell(no)",
      fn: function fn(_ref) {
        var index = _ref.index;
        return [_vm._v(" " + _vm._s(index + 1) + " ")];
      }
    }, {
      key: "cell(no_invoice)",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(item.no_invoice ? item.no_invoice : "-") + " ")];
      }
    }, {
      key: "cell(member_id)",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_vm._v(" " + _vm._s(item.member ? item.member.nama_lengkap : "-") + " ")];
      }
    }, {
      key: "cell(status_bayar)",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [item.status_bayar == 'lunas' ? _c('b-badge', {
          attrs: {
            "variant": "success"
          }
        }, [_vm._v("LUNAS")]) : _c('b-badge', {
          attrs: {
            "variant": "danger"
          }
        }, [_vm._v("BELUM LUNAS")])];
      }
    }, {
      key: "cell(status)",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [item.status == 'selesai' ? _c('b-badge', {
          attrs: {
            "variant": "success"
          }
        }, [_vm._v("SELESAI")]) : item.status == 'diproses' ? _c('b-badge', {
          attrs: {
            "variant": "warning"
          }
        }, [_vm._v("DIPROSES")]) : item.status == 'dikirim' ? _c('b-badge', {
          attrs: {
            "variant": "primary"
          }
        }, [_vm._v("DIKIRIM")]) : item.status == 'diterima' ? _c('b-badge', {
          attrs: {
            "variant": "info"
          }
        }, [_vm._v("DITERIMA")]) : item.status == 'dibatalkan' ? _c('b-badge', {
          attrs: {
            "variant": "danger"
          }
        }, [_vm._v("DIBATALKAN")]) : _c('b-badge', {
          attrs: {
            "variant": "secondary"
          }
        }, [_vm._v("PENDING")])];
      }
    }, {
      key: "cell(rincian)",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_vm._v(" " + _vm._s(item.rincian[0] ? item.rincian[0].nama_barang : "-") + " ")];
      }
    }, {
      key: "cell(waktu_transaksi)",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [_c('strong', [_vm._v(_vm._s(_vm.humanDateTime(item.waktu_transaksi)))])];
      }
    }, {
      key: "cell(uang_masuk)",
      fn: function fn(_ref8) {
        var item = _ref8.item;
        return [_c('strong', [_vm._v("Rp " + _vm._s(_vm.formatRupiah(item.uang_masuk)))])];
      }
    }, {
      key: "cell(total_bayar)",
      fn: function fn(_ref9) {
        var item = _ref9.item;
        return [_c('strong', [_vm._v("Rp " + _vm._s(_vm.formatRupiah(item.total_bayar)))])];
      }
    }, {
      key: "cell(action)",
      fn: function fn(_ref10) {
        var item = _ref10.item;
        return [_c('b-button', {
          attrs: {
            "size": "sm",
            "variant": "warning"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.$router.push("/transaksi-penjualan/detail/".concat(item.id));
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "EyeIcon"
          }
        })], 1), item.status == 0 && _vm.allowUpdate() && item.tak_tertagih == 0 && item.bayar == 0 ? _c('b-button', {
          attrs: {
            "size": "sm",
            "variant": "outline-warning"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.$router.push("/transaksi-penjualan/edit/".concat(item.id));
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "EditIcon"
          }
        })], 1) : _vm._e()];
      }
    }, {
      key: "cell(actionFinance)",
      fn: function fn(_ref11) {
        var item = _ref11.item;
        return [_c('b-button', {
          attrs: {
            "size": "sm",
            "variant": "warning"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.$router.push("/transaksi-penjualan/detail/".concat(item.id));
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "EyeIcon"
          }
        })], 1), item.status_bayar != 'lunas' ? _c('b-button', {
          attrs: {
            "title": "Konfirmasi Pembayaran",
            "size": "sm",
            "variant": "primary"
          },
          on: {
            "click": function click($event) {
              return _vm.aksiKonfirmasi(item);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "ListIcon"
          }
        })], 1) : _vm._e(), _c('b-modal', {
          attrs: {
            "hide-backdrop": "",
            "no-close-on-esc": "",
            "no-close-on-backdrop": "",
            "id": "modal-konfirmasi",
            "size": "xl",
            "ok-variant": "secondary",
            "centered": "",
            "title": 'Konfirmasi Pembayaran ' + _vm.formkonfirmasi.no_invoice
          },
          scopedSlots: _vm._u([{
            key: "modal-footer",
            fn: function fn() {
              return [_c('section', {
                staticClass: "d-flex justify-content-end align-items-center"
              }, [_c('b-button', {
                attrs: {
                  "size": "sm",
                  "variant": "danger"
                },
                on: {
                  "click": function click($event) {
                    _vm.showKonfirmasi = false;
                    _vm.getTransaksi();
                  }
                }
              }, [_vm._v(" Keluar ")])], 1)];
            },
            proxy: true
          }], null, true),
          model: {
            value: _vm.showKonfirmasi,
            callback: function callback($$v) {
              _vm.showKonfirmasi = $$v;
            },
            expression: "showKonfirmasi"
          }
        }, [_c('b-row', [_c('b-col', {
          attrs: {
            "xl": "6",
            "lg": "6",
            "md": "12",
            "sm": "12"
          }
        }, [_c('b-card', [_c('validation-observer', {
          ref: "vkonfirmasi"
        }, [_c('b-form', [_c('b-row', [_c('b-col', {
          attrs: {
            "sm": "12",
            "md": "12"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Jenis Transaksi"
          }
        }, [_c('b-form-select', {
          attrs: {
            "options": [{
              value: 1,
              text: 'Debit'
            }, {
              value: 2,
              text: 'Kredit'
            }],
            "id": "v-transaction_type",
            "name": "transaction_type"
          },
          model: {
            value: _vm.transactionType,
            callback: function callback($$v) {
              _vm.transactionType = $$v;
            },
            expression: "transactionType"
          }
        })], 1)], 1), _vm.transactionType ? _c('b-col', {
          attrs: {
            "sm": "12",
            "md": "12"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Pilih Akun"
          }
        }, [_c('validation-provider', {
          attrs: {
            "rules": "required",
            "name": "id_akun"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref12) {
              var errors = _ref12.errors;
              return [_c('b-form-select', {
                attrs: {
                  "options": _vm.id_akun,
                  "id": "v-id_akun",
                  "name": "id_akun"
                },
                model: {
                  value: _vm.formkonfirmasi.id_akun,
                  callback: function callback($$v) {
                    _vm.$set(_vm.formkonfirmasi, "id_akun", $$v);
                  },
                  expression: "formkonfirmasi.id_akun"
                }
              }), _c('small', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])];
            }
          }], null, true)
        })], 1)], 1) : _vm._e(), _c('b-col', {
          attrs: {
            "sm": "12",
            "md": "12"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Pilih Kas"
          }
        }, [_c('validation-provider', {
          attrs: {
            "rules": "required",
            "name": "id_kas"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref13) {
              var errors = _ref13.errors;
              return [_c('b-form-select', {
                attrs: {
                  "options": _vm.id_kas,
                  "id": "v-id_kas",
                  "name": "id_kas"
                },
                model: {
                  value: _vm.formkonfirmasi.id_kas,
                  callback: function callback($$v) {
                    _vm.$set(_vm.formkonfirmasi, "id_kas", $$v);
                  },
                  expression: "formkonfirmasi.id_kas"
                }
              }), _c('small', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])];
            }
          }], null, true)
        })], 1)], 1)], 1)], 1)], 1), _c('b-button', {
          attrs: {
            "variant": "primary",
            "block": ""
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.submitkonfirmasi($event);
            }
          }
        }, [_vm._v(" Konfirmasi ")])], 1)], 1), _c('b-col', {
          attrs: {
            "xl": "6",
            "lg": "6",
            "md": "12",
            "sm": "12"
          }
        }, [_c('b-card', [_vm.formkonfirmasi.bukti_tf ? [_c('b-img', {
          attrs: {
            "src": _vm.apiFile + _vm.formkonfirmasi.bukti_tf.file,
            "alt": "",
            "fluid-grow": ""
          }
        })] : [_c('center', [_c('h3', {
          staticClass: "text-danger"
        }, [_vm._v("Bukti TF belum diupload")])])]], 2)], 1)], 1)], 1)];
      }
    }])
  })], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-pagination', {
    staticClass: "my-0",
    attrs: {
      "total-rows": _vm.totalRows,
      "per-page": _vm.perPage,
      "align": "center",
      "size": "sm"
    },
    model: {
      value: _vm.currentPage,
      callback: function callback($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }